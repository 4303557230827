import { createContext, useContext, useState } from 'react';

import type { ReactNode } from 'react';
import type { Theme, Brand, SocialMediaLinkList } from '..';

export interface BrandProviderContext {
  brand: {
    theme?: Theme;
    colors?: { background?: string };
    images?: { coverImageUrl?: string; profileImageUrl?: string };
    socialMedia?: SocialMediaLinkList;
    name?: string;
    profileSummary?: string;
  };
  setBrand: (brand: Brand) => void;
}

const BrandContext = createContext({
  brand: {},
  setBrand: () => {},
} as BrandProviderContext);

export const useBrandContext = () => useContext(BrandContext);

export const BrandProvider = ({
  children,
  brand,
}: {
  children: ReactNode;
  brand: Brand;
}) => {
  const [updatedBrand, setUpdatedBrand] = useState(brand);
  return (
    <BrandContext.Provider
      value={
        {
          brand: updatedBrand,
          setBrand: setUpdatedBrand,
        } as BrandProviderContext
      }
    >
      {children}
    </BrandContext.Provider>
  );
};

export default BrandProvider;
